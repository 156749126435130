<template>
    <div class="m-0 px-3 pt-4 pb-0 pr-2 manage-shop-show w-75" v-loading="loaders.fetching">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <div>
                <h1>Ask The Nurse</h1>
            </div>
        </div>
        <ask-the-nurse v-if="askTheNurse" :ask-the-nurse="askTheNurse" />
    </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import AskTheNurse from "@/views/Components/SuperAdmin/AskTheNurse/Card";

export default {
    components: {
        BackButton,
        AskTheNurse
    },
    data() {
        return {
            loaders: {
                fetching: false,
            },
            askTheNurse: null,
        };
    },
    mounted() {
        this.getAskTheNurse();
    },
    methods: {
        async getAskTheNurse() {
            this.loaders.fetching = true;
            try {
                let response = await this.$store.dispatch('_getAskTheNurse');
                let {
                    data: { data },
                } = response;
                if(!data) this.$router.push({name:"admin.ask-the-nurse.edit"})
                this.askTheNurse = data;
            } catch (error) {
            }
            this.loaders.fetching = false;
        },
    },
};
</script>
<style scoped lang="scss"></style>
  